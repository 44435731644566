<template>
  <div>
    <b-breadcrumb
      class="mb-1"
      :items="[
        {
          text: 'Planejador',
          active: true, // Não tem link
        },
        {
          text: 'Conteúdos',
          active: true,
        },
      ]"
    />

    <b-card-actions
      ref="filters"
      title="Filtros"
      class="form-filters"
      :action-close="false"
      :action-refresh="false"
      :action-collapse="true"
      @refresh="refreshStop('cardAction')"
    >
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Título/Texto"
            label-for="content"
          >
            <b-form-input
              id="content"
              v-model="content"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Dificuldade"
            label-for="difficulty"
          >
            <b-form-select
              id="difficulty"
              v-model="difficulty"
              :options="plannerActionDifficultiesOptions"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Público"
            label-for="type"
          >
            <b-form-select
              id="type"
              v-model="type"
              :options="userTypeOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="text-right">
        <b-col
          cols="12"
          class="form-buttons"
        >
          <b-form-group
            label=""
            label-for="btn-buscar"
            style="margin-top: 20px"
          >
            <b-button
              id="btn-limpar"
              variant="outline-secondary"
              @click="reset()"
            >
              Limpar
            </b-button>
            <b-button
              id="btn-buscar"
              variant="gradient-primary"
              @click="getContents()"
            >
              Buscar
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card>
      <div class="d-flex align-items-center justify-content-between">
        <h4>Resultados</h4>
        <b-button
          v-if="$can('edit', 'Planner')"
          :to="{ name: 'cms-planner-contents-edit', params: { content: {} } }"
          variant="gradient-primary"
        >
          Adicionar
        </b-button>
      </div>
      <div
        v-if="isLoading"
        class="text-center table-spinner"
      >
        <b-spinner label="Loading..." /><br />
        <strong>Carregando...</strong>
      </div>
      <div v-else>
        <list-content
          v-for="content in contents"
          :key="content.id"
          :content="content"
        />
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BSpinner,
  BButton,
  BRow,
  BCol,
  BCard,
  BBreadcrumb,
  BFormGroup,
  BFormSelect,
  BFormInput,
} from 'bootstrap-vue'
import PlannerService from '@/services/plannerService'
import {
  plannerActionDifficultiesOptions,
  withEmptyOptionFirst,
  userTypes,
} from '@/utils/options'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ListContent from './ListContent.vue'

export default {
  name: 'PlannerContentList',
  components: {
    ListContent,
    BCardActions,
    BSpinner,
    BButton,
    BRow,
    BCol,
    BCard,
    BBreadcrumb,
    BFormGroup,
    BFormSelect,
    BFormInput,
  },
  data() {
    const initialFilterForm = {
      difficulty: '',
      type: '',
      content: '',
    }

    return {
      plannerActionDifficultiesOptions: withEmptyOptionFirst(
        plannerActionDifficultiesOptions,
      ),
      userTypeOptions: withEmptyOptionFirst([...userTypes, ...[{ value: 'all', text: 'Ambos' }]]),

      isLoading: true,
      contents: [],
      ...initialFilterForm,
      initialFilterForm,
    }
  },
  computed: {
    filterForm() {
      const fields = Object.keys(this.initialFilterForm)
      const entries = fields.map(k => [k, this[k]])
      return Object.fromEntries(entries)
    },
  },
  created() {
    this.getContents()
  },
  methods: {
    reset() {
      Object.assign(this, this.initialFilterForm)
    },
    async getContents() {
      this.isLoading = true

      try {
        const response = await PlannerService.content.list(this.filterForm)
        this.contents = response.data.data
      } catch (error) {
        const modal = {}
        if (error.response) {
          Object.assign(modal, {
            title: 'Erro ao carregar conteúdos',
            text: Object.values(error.response.data.errors),
            icon: 'error',
          })
        } else {
          Object.assign(modal, {
            title: 'Erro ao carregar conteúdos',
            text: error.message,
            icon: 'error',
          })
        }

        this.$swal({
          ...modal,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
