<template>
  <b-card
    v-if="content"
    class="planner__content-list__content"
    :class="{
      'is-deleting': isDeleting,
    }"
  >
    <b-row>
      <b-col
        class="d-flex flex-column align-items-center text-center side-column"
      >
        <b-badge
          v-if="content.type"
          pill
          :variant="content.type === 'company' ? 'light-warning' : 'light-info'"
        >
          {{ $t(`planner.content.user_type.${content.type}`) }}
        </b-badge>
        <b-avatar
          class="mt-1"
          circle
          size="42"
          :style="{
            backgroundColor: difficulties[content.difficulty].colors.secondary,
            color: difficulties[content.difficulty].colors.primary,
          }"
        >
          <svg
            width="18"
            height="18"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 65 66"
          >
            <path
              fill="currentColor"
              d="M62.46 7.6h-8.69a.58.58 0 0 1-.58-.58V5.27A4.63 4.63 0 0 0 48.57.66L16.23.7a4.62 4.62 0 0 0-4.63 4.6V7a.58.58 0 0 1-.58.59h-8.7a2.31 2.31 0 0 0-2.3 2.3v2.32c0 7.88 4.33 16.25 11.05 18.12a1.13 1.13 0 0 1 .8.85c.84 3.89 3.4 7.59 7.43 10.64a26.61 26.61 0 0 0 9.87 4.7 1.16 1.16 0 0 1 .9 1.12V60.2a.58.58 0 0 1-.57.58h-8.6c-1.24 0-2.31.96-2.37 2.2a2.31 2.31 0 0 0 2.3 2.43H43.9c1.25 0 2.32-.96 2.38-2.2a2.31 2.31 0 0 0-2.31-2.43H35.3a.58.58 0 0 1-.58-.58V47.65a1.16 1.16 0 0 1 .9-1.13c3.24-.72 6.85-2.4 9.87-4.7 4.03-3.04 6.6-6.74 7.43-10.63a1.13 1.13 0 0 1 .8-.85c6.72-1.87 11.06-10.24 11.06-18.12V9.91a2.31 2.31 0 0 0-2.31-2.31ZM11.59 24.68a.58.58 0 0 1-.87.5 10.6 10.6 0 0 1-3.2-3.16 17.88 17.88 0 0 1-2.86-9.2.58.58 0 0 1 .58-.6h5.78a.58.58 0 0 1 .58.58l-.01 11.88Zm45.68-2.66c-.63.94-1.71 2.28-3.2 3.16a.58.58 0 0 1-.86-.5L53.2 12.8a.58.58 0 0 1 .58-.58h5.78a.58.58 0 0 1 .58.6 17.88 17.88 0 0 1-2.87 9.2Z"
            />
          </svg>
        </b-avatar>
        <div
          class="font-weight-bolder mt-1"
          :style="{
            color: difficulties[content.difficulty].colors.primary,
          }"
        >
          {{ $t('planner.content.difficulties')[content.difficulty] }}<br>
          {{ content.duration }}
          {{ content.duration != 1 ? 'minutos' : 'minuto' }}
        </div>
      </b-col>
      <b-col>
        <b-card-title>
          {{ content.title }}
        </b-card-title>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="content.text" />
      </b-col>
      <b-col class="text-nowrap text-center side-column">
        <b-button
          v-if="$can('edit', 'Planner')"
          :to="{ name: 'cms-planner-contents-edit', params: { content } }"
          variant="flat-primary"
          title="Editar"
        >
          <feather-icon
            icon="EditIcon"
            size="16"
            class="align-middle text-body"
          />
        </b-button>

        <b-button
          v-if="$can('delete', 'Planner')"
          variant="flat-primary"
          title="Deletar"
          @click="handleRemove"
        >
          <feather-icon
            icon="TrashIcon"
            size="16"
            class="align-middle text-body"
          />
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BAvatar,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardTitle,
  BButton,
} from 'bootstrap-vue'
import PlannerService from '@/services/plannerService'
import difficulties from '../difficulties.json'

export default {
  name: 'PlannerContentListContent',
  components: {
    BAvatar,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardTitle,
    BButton,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      difficulties,
      isDeleting: false,
    }
  },
  methods: {
    async handleRemove() {
      this.$swal({
        title: 'Remover conteúdo?',
        text: 'Deseja realmente remover este conteúdo?',
        icon: 'question',
        confirmButtonText: 'Sim, remover',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.remove()
        }
      })
    },

    async remove() {
      this.isDeleting = true

      try {
        await PlannerService.content.remove(this.content.id)
        this.content = null
      } catch (error) {
        const modal = {}
        if (error.response) {
          Object.assign(modal, {
            title: 'Erro ao deletar conteúdo',
            text: Object.values(error.response.data.errors),
            icon: 'error',
          })
        } else {
          Object.assign(modal, {
            title: 'Erro ao deletar conteúdo',
            text: error.message,
            icon: 'error',
          })
        }

        this.$swal({
          ...modal,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

        this.isDeleting = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.planner__content-list__content {
  &.is-deleting {
    pointer-events: none;
    opacity: 0.33;
  }

  .side-column {
    max-width: 150px;
  }
}
</style>
