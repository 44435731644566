/* import/no-extraneous-dependencies */
import useJwt from '@/auth/jwt/useJwt'

export default class PlannerService {
  static content = {
    list(params) {
      return useJwt.post('/planner/content/list', {}, params)
    },

    remove(id) {
      return useJwt.post(`/planner/content/remove/${id}`)
    },

    store(params) {
      return useJwt.post('/planner/content/store', {}, params)
    },
  }

  static objective = {
    list(params) {
      return useJwt.post('/planner/objective/list', {}, params)
    },
  }
}
