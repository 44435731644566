var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content)?_c('b-card',{staticClass:"planner__content-list__content",class:{
    'is-deleting': _vm.isDeleting,
  }},[_c('b-row',[_c('b-col',{staticClass:"d-flex flex-column align-items-center text-center side-column"},[(_vm.content.type)?_c('b-badge',{attrs:{"pill":"","variant":_vm.content.type === 'company' ? 'light-warning' : 'light-info'}},[_vm._v(" "+_vm._s(_vm.$t(("planner.content.user_type." + (_vm.content.type))))+" ")]):_vm._e(),_c('b-avatar',{staticClass:"mt-1",style:({
          backgroundColor: _vm.difficulties[_vm.content.difficulty].colors.secondary,
          color: _vm.difficulties[_vm.content.difficulty].colors.primary,
        }),attrs:{"circle":"","size":"42"}},[_c('svg',{attrs:{"width":"18","height":"18","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 65 66"}},[_c('path',{attrs:{"fill":"currentColor","d":"M62.46 7.6h-8.69a.58.58 0 0 1-.58-.58V5.27A4.63 4.63 0 0 0 48.57.66L16.23.7a4.62 4.62 0 0 0-4.63 4.6V7a.58.58 0 0 1-.58.59h-8.7a2.31 2.31 0 0 0-2.3 2.3v2.32c0 7.88 4.33 16.25 11.05 18.12a1.13 1.13 0 0 1 .8.85c.84 3.89 3.4 7.59 7.43 10.64a26.61 26.61 0 0 0 9.87 4.7 1.16 1.16 0 0 1 .9 1.12V60.2a.58.58 0 0 1-.57.58h-8.6c-1.24 0-2.31.96-2.37 2.2a2.31 2.31 0 0 0 2.3 2.43H43.9c1.25 0 2.32-.96 2.38-2.2a2.31 2.31 0 0 0-2.31-2.43H35.3a.58.58 0 0 1-.58-.58V47.65a1.16 1.16 0 0 1 .9-1.13c3.24-.72 6.85-2.4 9.87-4.7 4.03-3.04 6.6-6.74 7.43-10.63a1.13 1.13 0 0 1 .8-.85c6.72-1.87 11.06-10.24 11.06-18.12V9.91a2.31 2.31 0 0 0-2.31-2.31ZM11.59 24.68a.58.58 0 0 1-.87.5 10.6 10.6 0 0 1-3.2-3.16 17.88 17.88 0 0 1-2.86-9.2.58.58 0 0 1 .58-.6h5.78a.58.58 0 0 1 .58.58l-.01 11.88Zm45.68-2.66c-.63.94-1.71 2.28-3.2 3.16a.58.58 0 0 1-.86-.5L53.2 12.8a.58.58 0 0 1 .58-.58h5.78a.58.58 0 0 1 .58.6 17.88 17.88 0 0 1-2.87 9.2Z"}})])]),_c('div',{staticClass:"font-weight-bolder mt-1",style:({
          color: _vm.difficulties[_vm.content.difficulty].colors.primary,
        })},[_vm._v(" "+_vm._s(_vm.$t('planner.content.difficulties')[_vm.content.difficulty])),_c('br'),_vm._v(" "+_vm._s(_vm.content.duration)+" "+_vm._s(_vm.content.duration != 1 ? 'minutos' : 'minuto')+" ")])],1),_c('b-col',[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.content.title)+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.content.text)}})],1),_c('b-col',{staticClass:"text-nowrap text-center side-column"},[(_vm.$can('edit', 'Planner'))?_c('b-button',{attrs:{"to":{ name: 'cms-planner-contents-edit', params: { content: _vm.content } },"variant":"flat-primary","title":"Editar"}},[_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"EditIcon","size":"16"}})],1):_vm._e(),(_vm.$can('delete', 'Planner'))?_c('b-button',{attrs:{"variant":"flat-primary","title":"Deletar"},on:{"click":_vm.handleRemove}},[_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"TrashIcon","size":"16"}})],1):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }